export const INIT_INVOICE = "INIT_INVOICE";
export const FETCH_INVOICE_LIST_START = "FETCH_INVOICE_LIST_START";
export const FETCH_INVOICE_LIST_SUCCESS = "FETCH_INVOICE_LIST_SUCCESS";
export const FETCH_INVOICE_LIST_ERROR = "FETCH_INVOICE_LIST_ERROR";

export const INIT_CREATE_NEW_INVOICE_SCREEN =
  'INIT_CREATE_NEW_INVOICE_SCREEN';

export const CREATE_INVOICE_RECORD = {
  START: "CREATE_INVOICE_RECORD_START",
  SUCCESS: "CREATE_INVOICE_RECORD_SUCCESS",
  ERROR: "CREATE_INVOICE_RECORD_ERROR",
};

export const EDIT_INVOICE_RECORD = {
  START: 'EDIT_INVOICE_RECORD_START',
  SUCCESS: 'EDIT_INVOICE_RECORD_SUCCESS',
  ERROR: 'EDIT_INVOICE_RECORD_ERROR',
};

export const FETCH_INVOICE_DETAIL = {
  START: 'FETCH_INVOICE_DETAIL_START',
  SUCCESS: 'FETCH_INVOICE_DETAIL_SUCCESS',
  ERROR: 'FETCH_INVOICE_DETAIL_ERROR',
};

export const DOWNLOAD_INVOICE = {
  START: 'DOWNLOAD_INVOICE_START',
  SUCCESS: 'DOWNLOAD_INVOICE_SUCCESS',
  ERROR: 'DOWNLOAD_INVOICE_ERROR'
};

export const TOAST = {
  RESET: "RESET_TOAST",
};
