import React from "react";
import { Divider } from "primereact/divider";
import { Row, Col } from "react-bootstrap";
import EzNotFound from "../../../common/EzNotFound";
import EzTable from "../../../common/EzTable";
import "./styles.css";

const getIconPath = (imgPathDelta = "/no-record.png") =>
  process.env.PUBLIC_URL + imgPathDelta;

const BilledFuelInvoice = (props) => {
  const {
    tableData,
    fuelTransactionDtoList,
    paginationInfo,
    screenPermissions,
    isPageLevelError,
  } = props;

  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderBilledFuelInvoiceRecords = () =>
    tableData?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderBilledFuelInvoiceRecords() && !shouldRenderFullPageError();

  const filters = {
    global: { value: null },
    auth_code: { filterKey: "auth_code", value: null },
    timestamp: { filterKey: "timestamp", value: null },
    merchant_number: { filterKey: "merchant_number", value: null },
    merchant_city: { filterKey: "merchant_city", value: null },
    amount: { filterKey: "amount", value: null },
    // add other fields
  };

  const tableProps = {
    // areFiltersVisible,
    columns: tableData?.columns,
    paginationInfo,
    screenPermissions,
    value: fuelTransactionDtoList,
    emptyMessage: "No Fuel Invoice found.",
    filters,
    loading: false,
    showMoreActions: false,
    sortField: null,
  };
  return (
    <div className="paddingbox">
      <div className="containertablebox">
        {renderDividerRow("Billed Fuel Invoices")}
        <div className="mTop15">
          {shouldRenderFullPageError() && (
            <EzNotFound
              messageStyle={{ marginLeft: "-70px" }}
              bgImgUrl={getIconPath("something-broke.png")}
              message={"Oops! Something broke, we are working on a fix."}
            />
          )}
          {shouldRenderBilledFuelInvoiceRecords() && (
            <Row>
              <Col md={12}>
                <EzTable {...tableProps} />
              </Col>
            </Row>
          )}
          {shouldRenderNotFoundView() && (
            <EzNotFound
              bgImgUrl={getIconPath()}
              message={"No User records to show"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const renderDividerRow = (label, align = "top", type = "solid") => {
  return (
    <Row>
      <b className="ml-2">{label || ""}</b>
      {/* <Divider align={align} type={type}></Divider> */}
    </Row>
  );
};

export default BilledFuelInvoice;
