import { Helmet } from "react-helmet";

const CustomHelmet = ({ title, faviconPath }) => (
  <Helmet>
    <title>{title}</title>
    <link rel="icon" href={faviconPath} />
  </Helmet>
);

export default CustomHelmet;
