import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Button, Form, FormControl } from "react-bootstrap";
import Cookies from "universal-cookie";

import { validateEmail } from "../utils/validation/email";
import { postApiLoginAsyn } from "../Services/PostAPI";
import { getMenuAccess } from "./login-utils";
import Logo from "../images/logo.svg";
import Loginbg from "../images/loginbg.jpg";
import "./Login.css";
import { selectBrandsConfig } from "../Redux/Selectors/brand";
import { connect } from "react-redux";
import CustomHelmet from "../utils/customHelmet";

const cookies = new Cookies();
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginValue: {},
      error: false,
      errorMessage: "",
      access: {},
    };
  }

  keyUpEventListener = (event) => {
    if (event.key === "Enter" && !this.checkButtonDisabled()) {
      this.handleLoign();
    }
  };

  componentDidMount = () => {
    window.addEventListener("keyup", this.keyUpEventListener);
  };

  componentWillUnmount = () => {
    window.removeEventListener("keyup", this.keyUpEventListener);
  };

  checkError = (value) => {
    if (value && !validateEmail(value)) {
      return "Please enter a valid email.";
    }
    return false;
  };

  checkButtonDisabled = () => {
    const { loginValue } = this.state;
    return (
      !loginValue.username ||
      !loginValue.password ||
      this.checkError(loginValue.username)
    );
  };

  handleChangeGen = (event) => {
    const { loginValue } = this.state;
    loginValue[event.target.name] = event.target.value;
    this.setState({
      [loginValue]: loginValue,
      error: false,
    });
  };

  handleLoign = async () => {
    const { loginValue } = this.state;
    let reqData = {
      username: loginValue.username,
      password: loginValue.password,
    };
    let res = await postApiLoginAsyn("login", reqData);
    if (res.authorization != null) {
      console.log(res);
      await getMenuAccess(res.authorization);
      cookies.set("authorization", res.authorization, {
        path: "/",
        sameSite: true,
      });
      this.props.callBack();
    } else {
      console.log(res);
      this.setState({
        error: true,
        errorMessage: "Invalid Credential",
      });
    }
  };

  render() {
    const { brandConfig } = this.props;
    const { name, faviconPath } = brandConfig?.config || {};
    const { loginValue, error, errorMessage } = this.state;
    if (
      cookies.get("authorization") != null ||
      cookies.get("authorization") !== undefined
    ) {
      return <Redirect to="/Dashboard" />;
    }
    return (
      <div className="login">
        <CustomHelmet title={`Welcome to ${name}`} faviconPath={faviconPath} />
        <div className="left_login">
          <img
            src={Loginbg}
            alt="FF"
            style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          />
        </div>

        <div className="loginpan">
          <div className="loginlogo">
            {/* <img src={Logo} alt="Easy On Road" /> */}
            <div className="formrow">
              <h2>Welcome to {name}</h2>
              {error === true ? (
                <div className="login-error">
                  {errorMessage} Please try again.
                </div>
              ) : null}
              <Form.Group>
                <Form.Label className="user_icon">Email</Form.Label>
                <FormControl
                  name="username"
                  value={loginValue.username}
                  onChange={this.handleChangeGen}
                  placeholder="Enter Email"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="pass_icon">Password</Form.Label>
                <FormControl
                  type="password"
                  name="password"
                  onChange={this.handleChangeGen}
                  placeholder="Enter Password"
                />
              </Form.Group>
              <Button
                disabled={this.checkButtonDisabled()}
                type="button"
                variant="primary"
                onClick={this.handleLoign}
              >
                Sign in
              </Button>{" "}
              <div className="forget">
                <p>
                  Don't have an account? <a target="_blank" href="https://app.easyonbooks.com/register.jsf?jfwid=3043">Sign up.</a>{" "}
                </p>
                <p><a target="_blank" href="https://app.easyonbooks.com/reset_password.jsf?jfwid=-5950">Forgot password?</a> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    brandConfig: selectBrandsConfig(state)
  }
}
export default connect(mapStateToProps, null)(Login);
