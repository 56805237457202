export const downloadBlob = (data, fileName, mimeType) => {
  try {
    const blob = new Blob([data], { type: mimeType });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("Error in downloadBlob utility:", error);
  }
};
