import { DEFAULT_TOAST_LIFE } from "../../../common/EzToast";
import {
  CREATE_INVOICE_RECORD,
  DOWNLOAD_INVOICE,
  EDIT_INVOICE_RECORD,
  FETCH_INVOICE_DETAIL,
  FETCH_INVOICE_LIST_ERROR,
  FETCH_INVOICE_LIST_START,
  FETCH_INVOICE_LIST_SUCCESS,
  INIT_CREATE_NEW_INVOICE_SCREEN,
  INIT_INVOICE,
  TOAST
} from "../../../constants/action-types/invoice";
import { invoiceService } from "../../../Services";
import { goBack } from "../../../utils/gotoback";

export const initializeInvoiceScreen = () => ({ type: INIT_INVOICE });

export const invoiceFetchStart = (payload) => ({
  type: FETCH_INVOICE_LIST_START,
  payload,
});

export const invoiceFetchSuccess = (payload) => ({
  type: FETCH_INVOICE_LIST_SUCCESS,
  payload,
});

export const invoiceFetchError = (payload) => ({
  type: FETCH_INVOICE_LIST_ERROR,
  payload,
});

export const fetchInvoice = (payload) => {
  return (dispatch) => {
    dispatch(invoiceFetchStart(payload));
    invoiceService.fetchInvoice(payload).then((data) => {
      if (!data.isError) {
        dispatch(invoiceFetchSuccess(data));
      
      } else {
        dispatch(invoiceFetchError(data));
      }
    });
  };
};

// create invoice
export const createInvoiceRecordStart = () => ({
  type: CREATE_INVOICE_RECORD.START,
});

export const createInvoiceRecordSuccess = (payload) => ({
  type: CREATE_INVOICE_RECORD.SUCCESS,
  payload,
});

export const createInvoiceRecordError = (payload) => ({
  type: CREATE_INVOICE_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createInvoice = (payload) => {
  return (dispatch) => {
    dispatch(createInvoiceRecordStart());
    invoiceService.createInvoiceRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createInvoiceRecordSuccess());
        goBack();
      } else {
        dispatch(createInvoiceRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

// Edit Invoice
export const editInvoiceRecordStart = (payload) => ({
  type: EDIT_INVOICE_RECORD.START,
  payload
});

export const editInvoiceRecordSuccess = (payload) => ({
  type: EDIT_INVOICE_RECORD.SUCCESS,
  payload,
});

export const editInvoiceRecordError = (payload) => ({
  type: EDIT_INVOICE_RECORD.ERROR,
  payload,
});

export const editInvoice = (payload) => {
  return (dispatch) => {
    dispatch(editInvoiceRecordStart());
    invoiceService.editInvoiceRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editInvoiceRecordSuccess());
        goBack();
      } else {
        dispatch(editInvoiceRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

// fetch invoice by id
export const invoiceDetailFetchStart = (payload) => ({
  type: FETCH_INVOICE_DETAIL.START,
  payload,
});

export const invoiceDetailFetchSuccess = (payload) => ({
  type: FETCH_INVOICE_DETAIL.SUCCESS,
  payload,
});

export const invoiceDetailFetchError = (payload) => ({
  type: FETCH_INVOICE_DETAIL.ERROR,
  payload,
});

export const fetchInvoiceById = (payload) => {
  return (dispatch) => {
    dispatch(invoiceDetailFetchStart(payload));

    invoiceService.fetchInvoiceById(payload)
      .then((invoiceData) => {
        if (!invoiceData.isError) {
          dispatch(invoiceDetailFetchSuccess(invoiceData));
        } else {
          dispatch(invoiceDetailFetchError(invoiceData));
        }
      });
  };
};

export const createNewInvoiceScreenInitStart = (payload) => ({
  type: INIT_CREATE_NEW_INVOICE_SCREEN,
  payload,
});

export const initCreateNewInvoiceScreen = (payload) => {
  return (dispatch) => {
    dispatch(createNewInvoiceScreenInitStart());
  };
};

// download invoice
export const downloadInvoiceStart = () => ({
  type: DOWNLOAD_INVOICE.START
});

export const downloadInvoiceSuccess = (payload) => ({
  type: DOWNLOAD_INVOICE.SUCCESS,
  payload
});

export const downloadInvoiceError = (payload) => ({
  type: DOWNLOAD_INVOICE.ERROR,
  payload
});

export const downloadInvoice = (invoiceNumber) => {
  return (dispatch) => {
    dispatch(downloadInvoiceStart());

    invoiceService.downloadInvoice(invoiceNumber)
      .then((response) => {
        if (response.isError) {
          dispatch(downloadInvoiceError(response));
        } else {
          dispatch(downloadInvoiceSuccess(response));
        }
      })
      .catch((error) => {
        dispatch(downloadInvoiceError(error));
      });
  };
};