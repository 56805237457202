import {
  CREATE_INVOICE_RECORD,
  DOWNLOAD_INVOICE,
  EDIT_INVOICE_RECORD,
  FETCH_INVOICE_DETAIL,
  FETCH_INVOICE_LIST_ERROR,
  FETCH_INVOICE_LIST_START,
  FETCH_INVOICE_LIST_SUCCESS,
  INIT_CREATE_NEW_INVOICE_SCREEN,
  INIT_INVOICE,
  TOAST,
} from "../../../constants/action-types/invoice";


const formFieldValueMap = {
  invoice_number: '',
  company_name: '',
  issued_date: null,
  due_date: null,
  start_date: null,
  end_date: null,
  status: '',
  total_amount: 0,
  amount_due: 0,
  currency: '',
};

export const INVOICE_ISSUE_DATE = 'issued_date';
export const INVOICE_DUE_DATE = 'due_date';
export const INVOICE_START_DATE = 'start_date';
export const INVOICE_END_DATE = 'end_date';
const isDateField = (key) => {
  const dateFields = [INVOICE_ISSUE_DATE, INVOICE_DUE_DATE, INVOICE_START_DATE, INVOICE_END_DATE];
  return dateFields.includes(key);
};

const INITIAL_STATE = {
  invoiceListView: [],
  isLoading: false,
  error: null,
  formFieldValueMap,
  fuelTransactionDtoList: [],
  isInvoiceSuccess: false,
  isInvoiceError: false,
  isInvoiceSuccess: false,
  isInvoiceError: false,
  isCreateInvoiceSuccess: false,
  isCreateInvoiceError: false,
  isEditInvoiceSuccess: false,
  isEditInvoiceError: false,
  isInvoiceDetailSuccess: false,
  isInvoiceDetailError: false,
  isDownloadSuccess: false,
  isDownloadError: false,
};

export const invoiceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INIT_INVOICE:
      return { ...INITIAL_STATE, isLoadingPage: true };
    case FETCH_INVOICE_LIST_START:
    case CREATE_INVOICE_RECORD.START:
    case EDIT_INVOICE_RECORD.START:
    case FETCH_INVOICE_DETAIL.START:
    case DOWNLOAD_INVOICE.START:
      return { ...state, isLoading: true };

    case FETCH_INVOICE_LIST_SUCCESS: {
      const { pageable, total_elements, content } = action.payload;

      let resetState = {
        invoiceListView: [],
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
      };

      if (!content) {
        return resetState;
      }
      const { page_number, page_size } = pageable;
      const total_count = 50;

      return {
        ...resetState,
        invoiceListView: content,
        paginationInfo: {
          pageSize: page_size,
          pageNumber: page_number,
          totalPages: content.length,
          total_elements: total_count || total_elements,
        },
      };
    }
    case FETCH_INVOICE_LIST_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        invoiceListView: [],
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
      };
    }
    case INIT_CREATE_NEW_INVOICE_SCREEN: {
      return { ...INITIAL_STATE };
    }
    case CREATE_INVOICE_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateInvoiceSuccess: false,
        isCreateInvoiceError: true,
      };
    }
    case FETCH_INVOICE_DETAIL.SUCCESS: {
      const { invoice_dto, fuel_transaction_dto_list } = action.payload;

      const updatedFormFieldValueMap = {
        ...state.formFieldValueMap,
        invoice_number: invoice_dto?.invoice_number || '',
        company_name: invoice_dto?.company_name || '',
        issued_date: invoice_dto?.issued_date || null,
        due_date: invoice_dto?.due_date || null,
        start_date: invoice_dto?.start_date || null,
        end_date: invoice_dto?.end_date || null,
        status: invoice_dto?.status || '',
        total_amount: invoice_dto?.total_amount || 0,
        amount_due: invoice_dto?.amount_due || 0,
        currency: invoice_dto?.currency || '',
      };

      return {
        ...state,
        formFieldValueMap: updatedFormFieldValueMap,
        fuelTransactionDtoList: fuel_transaction_dto_list || [],
        isInvoiceDetailSuccess: true,
        isInvoiceDetailError: false,
        error: null,
        isLoading: false
      };
    }
    case FETCH_INVOICE_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isInvoiceDetailSuccess: false,
        isInvoiceDetailError: true,
      };
    }
    case EDIT_INVOICE_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditInvoiceSuccess: false,
        isEditInvoiceError: true,
      };
    }
    case EDIT_INVOICE_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditInvoiceSuccess: true,
        isEditInvoiceError: false,
      };
    }

    case DOWNLOAD_INVOICE.ERROR: {
      return {
        ...state,
        isLoading: false,
        isDownloadSuccess: false,
        isDownloadError: true,
      }
    }
    case DOWNLOAD_INVOICE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isDownloadSuccess: true,
        isDownloadError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateInvoiceSuccess: false,
        isCreateInvoiceError: false,
        isEditInvoiceSuccess: false,
        isEditInvoiceError: false,
        isInvoiceDetailError: false,
        isDownloadSuccess: false,
        isDownloadError: false,
      };
    }
    default:
      return state;
  }
};