import brandingConfig from "../../../config/brandingConfig";
import { SET_BRAND } from "../../../constants/action-types/brand";

const initialState = {
  brand: "freshfuels",
  config: brandingConfig["freshfuels"],
};


const brandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BRAND:
      return {
        ...state,
        brand: action.payload,
        config: brandingConfig[action.payload],
      };
    default:
      return state;
  }
};

export default brandingReducer;
