import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import classNames from "classnames";
import "./index.css";

const DownloadModal = ({
  visible,
  onHide,
  onExport,
  fileType,
  setFileType,
}) => {
  return (
    <Dialog
      header="NOTE: Export selected invoices to a ZIP file."
      visible={visible}
      style={{ width: "40vw" }}
      modal
      onHide={onHide}
      closable={true}
      className="download-modal"
      ariaCloseButtonLabel="Close"
    >
      <div className="p-fluid mt-4">
        <p>This will open a new tab with the file to download.</p>
        <p>Warning: This process may take several minutes.</p>

        <div className="d-flex align-items-center gap-3">
          <span className="fw-bold">File Type:</span>
          <div className="d-flex align-items-center">
            <RadioButton
              inputId="pdf"
              name="fileType"
              value="PDF"
              onChange={(e) => setFileType(e.value)}
              checked={fileType === "PDF"}
              ariaLabel="PDF File Type"
            />
            <label htmlFor="pdf" className="mt-2">
              PDF
            </label>
          </div>
          <div className="d-flex align-items-center">
            <RadioButton
              inputId="csv"
              name="fileType"
              value="CSV"
              onChange={(e) => setFileType(e.value)}
              checked={fileType === "CSV"}
              ariaLabel="CSV File Type"
            />
            <label htmlFor="csv" className="mt-2">
              CSV
            </label>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end gap-2 mt-4">
        <Button
          label="Export"
          onClick={onExport}
          className={classNames("p-button-raised mr-2", {
            disabled: !fileType,
          })}
        />
        <Button
          label="Close"
          onClick={onHide}
          className="p-btn-danger p-button-outlined"
        />
      </div>
    </Dialog>
  );
};

DownloadModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
  setFileType: PropTypes.func.isRequired,
};

export default DownloadModal;
