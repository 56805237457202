import Cookies from "universal-cookie";
import { API_URL } from "../../../constants/urlConstants";
import { post, put } from "axios";
import { baseUrl, getApiAsyn, getApiAsynDownloadBlob } from "../../PostAPI";
import { APIError, handleAPIError } from "../../common/errorHandler";
import { extractData } from "../../utils";
import { downloadBlob } from "../../utils/download";
import { getDecoratedUrl } from "../../common/urlService";

const cookies = new Cookies();


export const fetchInvoice = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.INVOICES.FETCH_INVOICE,
    payload,
  });
  
  try {
    let result = await getApiAsyn(url, cookies.get("authorization"));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
    // return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const createInvoiceRecord = async (payload) => {
  try {
    let result = await dataUploadApi(
      `${API_URL.INVOICES.CREATE_INVOICE}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editInvoiceRecord = async (payload) => {
  const url = API_URL.INVOICES.EDIT_INVOICES.replace(":id", payload?.id); //missing id

  try {
    let result = await dataUploadApi(url, payload.data, true);

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

const dataUploadApi = (url, data, isEdit = false) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: cookies.get("authorization"),
    },
  };

  if (isEdit) {
    return put(baseUrl + url, data, config);
  } else {
    return post(baseUrl + url, data, config);
  }
};

export const fetchInvoiceById = async (payload) => {
  const url = `${API_URL.INVOICES.FETCH_BY_INVOICE_ID}view?invoiceId=${payload?.id}`;
  try {
    let result = await getApiAsyn(url, cookies.get("authorization"));

    if (result?.type === "error") {
      throw new APIError(result);
    }

    // result = extractData(result, !payload?.filters);
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const downloadInvoice = async (invoiceNumber) => {
  const url = `${API_URL.INVOICES.FETCH_INVOICE_DOWNLOAD.replace(':invoiceNumber', invoiceNumber)}`;
  try {
    const response = await getApiAsynDownloadBlob(url, cookies.get('authorization'), true);
    if (response?.APIResult === 0) {
      throw new handleAPIError(response);
    }
    downloadBlob(response, 'invoice.pdf', 'application/pdf');
    return {
      isError: false,
      data: response,
    };
  } catch (error) {
    console.error('Download invoice error:', error);
    return {
      isError: true,
      error: handleAPIError(error),
    };
  }
};