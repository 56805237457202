import React from "react";
import { Route } from "react-router-dom";
import { APP_URLS } from "../../constants/urlConstants";
import InvoicesScreen from "../../containers/InvoicesScreen";
import CreateTransactions from "../../containers/TransactionsScreen/CreateTransactions";
import NotFound from "../../NotFound/NotFound";
import CreateInvoice from "../../containers/InvoicesScreen/CreateInvoices";
const InvoiceRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update  } = access;

  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <InvoicesScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {!!create && (
        <Route
          path={APP_URLS.INVOICES.ADD_INVOICES}
          component={CreateTransactions}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.INVOICES.EDIT_INVOICES}
          render={(routeProps) => (
            <CreateTransactions {...routeProps} isEdit={true} />
          )}
        />
      )}
      {!!read && (
        <Route
          path={APP_URLS.INVOICES.VIEW_INVOICES}
          render={(routeProps) => (
            <CreateInvoice {...routeProps} isView={true} />
          )}
        />
      )}
     
    </>
  );
};

export default InvoiceRoutes;
