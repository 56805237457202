import React, { Component } from 'react';

import NewHeader from './common/Header/NewHeader';
import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'universal-cookie';
import Routing from './Router/Routing';
import { getBrandFromURL } from './utils/getBrand';
import { connect } from 'react-redux';
import { setBrand } from './Redux/Actions/brand';
import { selectBrandsConfig } from './Redux/Selectors/brand';
import CustomHelmet from './utils/customHelmet';

const cookies = new Cookies();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCookiesSet: cookies.get('authorization'),
    };
  }

  componentDidMount() {
    const hostname = window.location.hostname;
    const { setBrand } = this.props;
    const brand = getBrandFromURL(hostname);
    setBrand(brand);
  }

  SwithToLogin = () => {
    this.setState({
      isCookiesSet: cookies.get('authorization'),
    });
  };
  render() {
    const { brandConfig } = this.props;
    const { name, faviconPath } = brandConfig?.config || {};
    if (
      this.state.isCookiesSet !== null &&
      this.state.isCookiesSet !== undefined
    ) {
      return (
        <>
          <div>
          <CustomHelmet title={`Welcome to ${name}`} faviconPath={faviconPath} />
            <NewHeader />
            <div className="comoncantainer">
              <Routing switchHeader={this.SwithToLogin} />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <Routing switchHeader={this.SwithToLogin} />
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    brandConfig: selectBrandsConfig(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  setBrand: (brand) => dispatch(setBrand(brand)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
